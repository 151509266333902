<template>
  <div v-show="alert && alert.status" class="alert-box round" :class="alert.type">
    <img :src="require('@/assets/images/'+alert.type+'_icon.svg')" :alt="alert.type" class="icon">
    <div class="alert-text">{{ alert.msg }}</div>
    <a href="javascript:void(0);" @click="closeAlert" class="close">&times;</a>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';


export default {
  computed: {
    ...mapState({
      alert: state => state.template.alert,
    }),
  },
  methods: {
    ...mapMutations({
      setAlert: 'template/setAlert',
    }),
    closeAlert() {
      this.setAlert({
        status: false,
        type: 'info',
        msg: null,
      });
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_alert.scss';
</style>
