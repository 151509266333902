<template>
  <div>
    <section class="main-content templates-container">
      <alert />
      <div class="grid-x grid-margin-x">
        <div class="cell sidebar large-3 select-client">
          <client-list
            v-on:ondelete="onDeleteClient"
            v-on:show-params="showReplacedText"
          />
        </div>
        <div v-show="step === 1" class="cell large-9 select-table">
          <div class="grid-x">
            <div class="search-container">
              <div class="action-buttons">
                <upload-file-csv v-show="showCsv" @load="getNumbers" />
                <button-filter
                  channelType="whatsapp"
                  :display="showDisplay"
                  v-on:change="onFiltersChanged"
                />
              </div>
            </div>
          </div>
          <filters
            :display="showDisplay"
            :channel="currentChannelId"
            channelType="whatsapp"
          />
          <send-templates-table
            :rows="rows"
            :pagination="pagination"
            @changePage="changePage"
            @search="submit"
          />
        </div>
        <div v-if="step === 2" class="columns large-9">
          <div>
            <div class="heading-step-2">{{ $t("templates.view") }}</div>
            <div class="sidebar preview-content">
              <div class="grid-x">
                <div class="large-6 preview-template">
                  <div class="header-preview">{{ $t("templates.chat") }}</div>
                  <div class="preview-text">
                    {{ parseText }}
                  </div>
                  <div class="time-text">
                    {{ today }}
                  </div>
                </div>
                <div class="large-6 input-labels">
                  <div class="heading-labels">{{ $t("templates.params") }}</div>
                  <div v-for="(param, i) in paramsForm" :key="i">
                    <p v-if="selectLabels && selectLabels[i]">
                      Seleccionaste la etiqueta de
                      <span class="select-label">{{ selectLabels[i] }}</span>
                      <a href="#" class="delete-label" @click="deleteLabel(i)">
                        Eliminar
                      </a>
                    </p>
                    <div class="display-flex">
                      <input
                        type="text"
                        :placeholder="'Parámetro ' + (i + 1)"
                        v-model.trim="params[i]"
                      />
                      <menu-dropdown
                        :labels="labels"
                        :input="i"
                        :label-select="selectLabels[i]"
                        v-on:select-label="selectedLabel"
                      />
                    </div>
                    <div
                      class="label-note"
                      v-if="selectLabels && selectLabels[i]"
                    >
                      *Debes poner un valor por default en caso de que no se
                      encuentre la etiqueta.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <available-templates v-show="step === 1" :items="templates" />
      <div class="row">
        <div class="columns button-actions">
          <hcc-button v-show="step === 1" :disabled="validStep1" @click="next">
            {{ $t("templates.next") }}
          </hcc-button>
        </div>
        <div class="secondary-actions">
          <div class="back-button">
            <hcc-button variant="outline" v-show="step === 2" @click="prev">
              {{ $t("templates.prev") }}
            </hcc-button>
          </div>
          <div class="send-button">
            <hcc-button
              v-show="step === 2"
              :disabled="btnDisabled"
              @click="send"
            >
              {{ $t("templates.send") }}
            </hcc-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ClientList from '@/components/ClientList.vue';
import AvailableTemplates from '@/components/AvailableTemplates.vue';
import ButtonFilter from '@/components/ButtonFilter.vue';
import Alert from '@/components/Alert.vue';
import MenuDropdown from '@/components/MenuDropdown.vue';
import UploadFileCsv from '@/components/UploadFileCsv.vue';
import listClientsGql from '@/graphql/queries/listClients.gql';
import listTemplatesGql from '@/graphql/queries/listTemplates.gql';
import campaingByIdGql from '@/graphql/queries/campaigns/byId.gql';
import executeQuery from '@/utils/gql-api';
import { onLogout } from '@/vue-apollo';
import api from '@/api';
import HccButton from '@/components/shared/HccButton/index.vue';
import SendTemplatesTable from '@/components/SendTemplatesTable.vue';
import Filters from '@/components/Filters.vue';


const redirectUrl = process.env.VUE_APP_REDIRECT_URL ?? 'https://app.heycenter.com';

export default {
  components: {
    ClientList,
    AvailableTemplates,
    ButtonFilter,
    Alert,
    MenuDropdown,
    UploadFileCsv,
    HccButton,
    SendTemplatesTable,
    Filters,
  },
  data() {
    return {
      showDisplay: ['company', 'campaign', 'channel'],
      currentCampaignId: null,
      currentChannelId: null,
      templates: [],
      data: [],
      pagination: {
        page: 1,
        perPage: 5,
        totalPages: 0,
      },
      search: null,
      step: 1,
      params: [],
      clientParams: [],
      btnDisabled: false,
      maxSendTemplate: 1000,
      labels: [],
      selectLabels: [],
      showCsv: false,
      csv: null,
      rows: [],
      filters: [],
      client: null,
      selectedChannel: null,
    };
  },
  computed: {
    ...mapState({
      clientList: state => state.template.clientList,
      templateSelected: state => state.template.templateSelected || null,
    }),
    paramsForm() {
      if (this.templateSelected
        && this.templateSelected.settings
        && this.templateSelected.settings.num_params) {
        return parseInt(this.templateSelected.settings.num_params, 10);
      }
      return 0;
    },
    parseText() {
      let { text } = this.templateSelected;
      this.allParams.forEach((param, index) => {
        const re = `{{${index + 1}}}`;
        text = text.replace(re, `{{${param}}}`);
      });
      return text;
    },
    today() {
      return this.$moment(new Date())
        .format('hh:mm A');
    },
    validStep1() {
      if (this.clientList && this.clientList.length !== 0 && this.templateSelected) {
        return false;
      }
      return true;
    },
    allParams() {
      return this.clientParams.concat(this.params);
    },
  },
  watch: {
    filters(newValue) {
      if (!newValue.channelId) {
        this.channel = null;
      }

      if (newValue.campaignId && newValue.channelId) {
        this.reset();
        this.deleteClientsList([]);
        this.getConversations(
          newValue.campaignId,
          newValue.channelId,
        );
        this.currentCampaignId = newValue.campaignId;
        this.currentChannelId = newValue.channelId;
        this.channel = newValue.channelId;
        this.getTemplates();
        this.getLabels();
        this.showCsv = true;
      } else if (newValue.campaignId || newValue.companyId) {
        this.reset();
      }
    },
  },
  methods: {
    ...mapMutations({
      toogleClientList: 'template/toogleClientList',
      toogleAvailableTemplate: 'template/toogleAvailableTemplate',
      deleteClientsList: 'template/deleteClientsList',
      setAlert: 'template/setAlert',
      setClientList: 'template/setClientList',
    }),
    reset() {
      this.templates = [];
      this.rows = [];
      this.pagination.page = 1;
      this.search = null;
      this.showCsv = false;
    },
    async getTemplates() {
      this.toogleAvailableTemplate(true);
      this.templates = await executeQuery('listTemplates',
        listTemplatesGql,
        {
          campaign_id: this.currentCampaignId,
          status: 'active',
        });
      this.toogleAvailableTemplate(false);
    },
    async getConversations(campaignId, channelId, page = 1, search = null) {
      this.toogleClientList(true);
      const { data, total } = await executeQuery('listClients',
        listClientsGql,
        {
          campaign_id: campaignId,
          channel_id: channelId,
          page,
          perPage: this.pagination.perPage,
          search,
        });
      this.rows = data;
      this.pagination.totalPages = total;
      this.toogleClientList(false);
    },
    async getLabels() {
      const campaing = await executeQuery('campaign',
        campaingByIdGql,
        {
          id: this.currentCampaignId,
        });
      if (campaing) {
        this.labels = campaing.labels;
      }
    },
    changePage(page) {
      if (this.pagination.page !== page) {
        this.pagination.page = page;
        this.getConversations(
          this.currentCampaignId,
          this.currentChannelId,
          page,
        );
      }
    },
    submit(props) {
      this.data = [];
      this.pagination.totalPages = 0;
      this.pagination.page = 1;
      this.getConversations(
        this.currentCampaignId,
        this.currentChannelId,
        this.pagination.page,
        props,
      );
    },
    next() {
      this.step = 2;
    },
    prev() {
      this.params = [];
      this.selectLabels = [];
      this.clientParams = [];
      this.step = 1;
    },
    send() {
      this.btnDisabled = true;
      const globalParams = this.params.map((tag, i) => {
        if (this.selectedLabel[i]) {
          return `TAG:${this.selectedLabel[i]}|${tag}`;
        }

        return tag;
      });

      const clients = this.clientList.map(({ username, params }) => {
        params.push(...globalParams);

        return {
          username,
          params: params.length >= this.paramsForm
            ? params.slice(0, this.paramsForm)
            : null,
        };
      });

      if (clients.filter(({ params }) => !params).length > 0) {
        this.setAlert({ status: true, type: 'error', msg: this.$t('templates.alerts.clients-without-params') });
        this.btnDisabled = false; return;
      }

      if (clients.length > this.maxSendTemplate) {
        this.setAlert({
          status: true,
          type: 'error',
          msg: this.$t('alerts.maxTemplate'),
        });
        this.btnDisabled = false;
        return;
      }
      const data = {
        template: this.templateSelected.id,
        channel: this.currentChannelId,
        clients,
      };
      this.btnDisabled = false;
      api.templateSend(data)
        .then((res) => {
          if (res.data && res.data.status) {
            this.prev();
            this.deleteClientsList([]);
            this.setAlert({
              status: true,
              type: 'info',
              msg: this.$t('alerts.inProcess'),
            });
          } else {
            this.setAlert({
              status: true,
              type: 'error',
              msg: this.$t('alerts.oops'),
            });
          }
          this.btnDisabled = false;
        })
        .catch((e) => {
          if (e.response.status === 401) {
            onLogout(this.$apollo.provider.defaultClient);
            window.location.href = redirectUrl;
          }
          this.setAlert({
            status: true,
            type: 'error',
            msg: this.$t('alerts.unexpectedError'),
          });
          this.btnDisabled = false;
        });
    },
    onDeleteClient() {
      if (this.clientList.length === 0) {
        this.prev();
      }
    },
    showReplacedText({ params }) {
      this.clientParams = params;
    },
    selectedLabel(item) {
      this.selectLabels[item.input] = null;
      this.selectLabels.splice(item.input, 1, item.label);
    },
    deleteLabel(index) {
      this.selectLabels.splice(index, 1, null);
    },
    getNumbers(e) {
      this.csv = e.split(/\r?\n/);
      const re = new RegExp(/(\+)?[0-9]{10,15}/);
      const clients = this.csv.filter(number => re.exec(number))
        .map((line) => {
          const [number, ...params] = line.split(',');
          return {
            id: null,
            fullName: null,
            username: number,
            lastConversation: null,
            type: 'csv',
            labels: [],
            params: params || [],
          };
        });
      this.setClientList(clients);
      let msg = `Se encontraron ${clients.length} números válidos `;
      if (clients.length !== this.csv.length) {
        const numberInvalid = this.csv.length - clients.length;
        msg += `y ${numberInvalid} números no son válidos.`;
      }
      this.setAlert({
        status: true,
        type: 'info',
        msg,
      });
    },
    onFiltersChanged(filters) {
      this.filters = filters;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  background: #fff;
  border: 1px solid #ededed;
}
@import "~styles/views/_sendTemplates.scss";
</style>
