/* eslint-disable max-len */
<template>
  <div class="header">
    <nav class="header__nav">
      <ul class="header__navbar">
        <li class="header__item">
          <a href="#" class="header__link">
            <transition name="slide-fade">
              <!-- Header Navigation Menu Icons -->
              <button class="header--button" v-if="show" key="on" @click="show = false">
                <svg viewBox="0 0 24 24" class="header--icon">
                  <title>Close</title>
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    fill="currentColor"
                    d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59
                    7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0
                    1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39
                    1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41
                    0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                  />
                </svg>
              </button>
              <button class="header--button" v-else key="off" @click="show = true">
                <svg viewBox="0 0 24 24" class="header--icon">
                  <title>Navigation Menu</title>
                  <path
                    fill="currentColor"
                    d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,
                    2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,
                    2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,
                    2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,
                    2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,
                    2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,
                    2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,
                    2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,
                    2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"
                  />
                </svg>
              </button>
            </transition>
          </a>
          <!-- Dropdown Menu -->
          <transition name="dropdown">
            <div class="dropdown__menu" v-bind:class="{ active: show }" v-if="show">
              <ul class="dropdown__menu-nav">
                <li
                  v-for="(label, i) in labels"
                  :key="i"
                  class="dropdown__menu-item"
                  @click="selectedLabel(label)">
                  <a
                    href="#"
                    class="dropdown__menu-link"
                    :class="{ 'active': labelSelect === label }"
                    :title="label"
                  >
                    <div class="dropdown__menu-text">{{ label }}</div>
                  </a>
                </li>
              </ul>
            </div>
          </transition>
        </li>
      </ul>
    </nav>
  </div>
</template>


<script>
export default {
  props: {
    input: {
      type: Number,
      required: true,
      default: 0,
    },
    labels: {
      type: Array,
      require: true,
      default: () => ([]),
    },
    labelSelect: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    selectedLabel(label) {
      const data = {
        label,
        input: this.input,
      };
      this.$emit('select-label', data);
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~styles/components/_menu-dropdown.scss';
</style>
