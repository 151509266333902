
<template>
  <vue-good-table
    v-bind="$attrs"
    v-on="$listeners"
    :rows="fullRows"
    :sort-options="{ enabled: false }"
    :columns="!emptyRows() ? fullColumns : []"
    ref="checkbox-table"
    :styleClass="!unclickable ? 'vgt-table checkbox' : 'vgt-table disabled'"
    :search-options="enableSearchTable()"
    :pagination-options="{
      enabled: enablePagination(),
      perPage: this.rowsPerPage,
    }"
    @on-row-click="handleCheck"
  >
    <div slot="emptystate" class="empty-rows">
      <div class="empty-rows__container">
        <img
          :src="require('@/assets/images/paper.svg')"
          class="empty-rows__icon"
        />
      </div>
      <span class="empty-rows__text">{{ emptyText }}</span>
    </div>
    <div slot="table-actions" class="table-actions" v-if="showTableActions">
      <span v-if="title" :title="title" class="table__title">{{ title }}</span>
      <div v-if="!title && showCheckedInfo" class="selection-info-container">
        <span class="selected-rows">{{ this.checkedRowsText }}</span>
        <span class="total-rows">{{ this.totalText }}</span>
      </div>
      <div class="table-actions__items" v-if="showSearchInput || searchService">
        <hcc-input
          :placeholder="searchText"
          v-model="searchValue"
          v-if="$listeners.search || enableSearch"
          @keyup.enter="$emit('search', searchValue)"
          ><template #icon><magnify-icon /></template
        ></hcc-input>
      </div>
    </div>
    <template slot="table-column" slot-scope="props" v-if="!unclickableAll">
      <span v-if="props.column.label === 'Check'" class="checkbox">
        <hcc-checkbox
          v-if="checkedRows.length === 0"
          :name="props.column.label"
          @change="checkAll"
          :disabled="unclickable"
        />
        <hcc-checkbox
          v-else
          :name="props.column.label"
          @change="clearChecked"
          :value="true"
          selected
          :disabled="unclickable"
        />
      </span>
      <span v-else>
        {{ props.column.label }}
      </span>
    </template>
    <template slot="table-row" slot-scope="props">
      <slot name="table-row" v-bind="props">
        <span v-if="props.column.field != 'check'
        && props.column.field !== 'labels' && props.column.field !== 'campaigns'">
          {{ props.formattedRow[props.column.field] }}
        </span>
        <span v-else-if="props.column.field === 'labels'">
          <div v-if="props.row.labels.length === 0">
            <span class="no-label">{{ $t('templates.no-tags') }}</span>
          </div>
          <div v-if="props.row.labels.length === 1">
            <span v-tooltip="props.row.labels[0].value">
              {{ props.row.labels[0].key }}
            </span>
          </div>
          <div
            @click.stop="() => {}"
            v-else-if="props.row.labels.length > 1"
          >
            <hcc-dropdown custom>
              <template #button>
                <hcc-button variant="outline" color="muted" size="sm">
                  {{ $t('templates.show-tags') }}
                </hcc-button>
              </template>
              <hcc-dropdown-item
                unclickable
                v-for="(label, j) in props.row.labels"
                :key="j"
              >
                {{ `${label.key}: ${label.value}` }}
              </hcc-dropdown-item>
            </hcc-dropdown>
          </div>
        </span>
        <span v-else-if="props.column.field === 'campaigns'">
          <div v-if="props.row.campaigns.length === 0">
            <span class="no-campaign">{{ $t("users['no-campaigns']") }}</span>
          </div>
          <div v-if="props.row.campaigns.length === 1">
            <span v-tooltip="props.row.campaigns[0].value">
              <span>{{ props.row.campaigns[0].name }}</span>
            </span>
          </div>
          <div
            @click.stop="() => {}"
            v-else-if="props.row.campaigns.length > 1"
          >
            <hcc-dropdown custom>
              <template #button>
                <hcc-button variant="outline" color="muted" size="sm">{{
                  $t("users['show-campaigns']")
                }}</hcc-button>
              </template>
              <hcc-dropdown-item
                unclickable
                v-for="(campaign) in props.row.campaigns"
                :key="campaign.id"
              >
                {{ campaign.name }}
              </hcc-dropdown-item>
            </hcc-dropdown>
          </div>
        </span>
        <span v-else class="checkbox__row">
          <hcc-checkbox
            :name="props.row.id"
            @change="handleCheck({ row: props.row })"
            :value="isCheckedRow(props.row.id)"
            :disabled="unclickable"
          />
        </span>
      </slot>
      <span v-if="props.column.field == 'actions'">
        <div v-if="showActions($listeners)">
          <hcc-button-icon
            v-if="$listeners.preview"
            @click.stop="$emit('preview', props.row)"
            :disabled="unclickable"
            ><eye-icon
          /></hcc-button-icon>
        </div>
      </span>
    </template>
    <template slot="pagination-bottom" slot-scope="props">
      <hcc-pagination
        :total="totalPages ? totalPages : props.total"
        :rowsPerPage="rowsPerPage"
        :actualPage="actualPage"
        :pageChanged="props.pageChanged"
        @page-change="newPage"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccDropdown from '@/components/shared/HccDropdown/index.vue';
import HccDropdownItem from '@/components/shared/HccDropdown/DropdownItem.vue';
import HccButtonIcon from '../HccButtonIcon/index.vue';
import HccPagination from './HccPagination.vue';
import HccInput from '../HccInput/index.vue';
import HccCheckbox from '../HccCheckbox/index.vue';

export default {
  components: {
    VueGoodTable,
    HccPagination,
    HccInput,
    HccCheckbox,
    HccButton,
    HccButtonIcon,
    HccDropdown,
    HccDropdownItem,
  },
  props: {
    title: {
      type: String,
    },
    columns: {
      type: Array,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    rowsPerPage: {
      type: Number,
      default: 5,
    },
    actualPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
    },
    unclickable: {
      type: Boolean,
      default: false,
    },
    unclickableAll: {
      type: Boolean,
      default: false,
    },
    searchService: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchValue: '',
      checkedRowsText: '',
      totalText: '',
      showCheckedInfo: false,
      checkedRows: [],
    };
  },
  computed: {
    fullColumns() {
      return this.showActions(this.$listeners) ? [...this.firstColumns,
        {
          label: this.$t('shared-components.table.actions'),
          field: 'actions',
          thClass: 'custom-th-class',
          tdClass: 'custom-td-class',
          sortable: false,
        }] : this.firstColumns;
    },
    firstColumns() {
      return [{
        label: 'Check',
        field: 'check',
        sortable: false,
      }, ...this.columns];
    },
    fullRows() {
      this.rows.forEach((item, index) => {
        this.$set(item, 'checked', false);
        this.$set(item, 'originalIndex', index);
      }, this);
      const newRows = this.rows;
      return newRows;
    },
    searchText() {
      return this.$t('common.search');
    },
    clearText() {
      return this.$t('shared-components.table.clear');
    },
    emptyText() {
      return this.$t('shared-components.table.empty');
    },
    showTableActions() {
      return this.title || this.$listeners.search || this.enableSearch;
    },
    showSearchInput() {
      return (this.$listeners.search || this.enableSearch) && !this.emptyRows();
    },
  },
  methods: {
    emptyRows() {
      return this.fullRows.length === 0;
    },
    enablePagination() {
      return this.emptyRows() ? false : !!this.$props.pagination;
    },
    isCheckedRow(index) {
      return this.checkedRows.some(
        row => row.id === index,
      );
    },
    clearChecked() {
      this.showCheckedInfo = false;
      this.checkedRows = [];
    },
    handleCheck({ row }) {
      const isChecked = this.isCheckedRow(row.id);

      if (isChecked) {
        const index = this.checkedRows.findIndex(
          r => r.id === row.id,
        );
        this.checkedRows.splice(index, 1);
        // eslint-disable-next-line no-param-reassign
        row.checked = false;
      } else {
        this.checkedRows.push(row);
        // eslint-disable-next-line no-param-reassign
        row.checked = true;
      }

      if (this.checkedRows.length > 0) {
        this.infoText();
        this.showCheckedInfo = true;
      } else {
        this.showCheckedInfo = false;
      }

      this.$emit('checked', this.checkedRows);
      this.$emit('checked-row', row);
    },
    checkAll() {
      this.rows.forEach((row, index) => {
        if (!row.checked) {
          this.$set(row, 'checked', true);
        }
        if (!this.isCheckedRow(row.id)) {
          this.checkedRows.push(row);
        } else {
          this.checkedRows.splice(index, 1);
        }
      });

      this.infoText();
      this.showCheckedInfo = true;


      this.$emit('check-all', this.checkedRows);
    },
    infoText() {
      this.totalRowsText();

      const totalChecked = this.checkedRows.length;

      this.checkedRowsText = totalChecked > 1
        ? `${totalChecked} ${this.$t('shared-components.table.rows-selected')}.`
        : `1 ${this.$t('shared-components.table.row-selected')}.`;
    },
    totalRowsText() {
      const total = this.rows.length;
      const totalChecked = this.checkedRows.length;

      if (totalChecked === total) {
        this.totalText = `${this.$t('shared-components.table.all-rows-selected')}.`;
      } else {
        this.totalText = total > 1
          ? `${total} ${this.$t('shared-components.table.total-rows')}.`
          : `1 ${this.$t('shared-components.table.row')}.`;
      }
    },
    onPageChange(params) {
      this.$emit('changePage', params);
    },
    newPage(newPage) {
      this.$emit('pageChanged', newPage);
    },
    showActions(listeners) {
      const eventListeners = Object.keys(listeners).filter(
        listener => (listener !== 'checked'
          && listener !== 'check-all'
          && listener !== 'pageChanged'
          && listener !== 'search'),
      ).length;

      return eventListeners.length;
    },
    enableSearchTable() {
      return this.enableSearch ? { enabled: true, externalQuery: this.searchValue }
        : { enabled: false };
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox {
  display: flex;
  justify-content: center;

  &__row {
    @extend .checkbox;
    padding-right: 12px;
  }
}
</style>
